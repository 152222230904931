<!--
    here we have the full layout of the game
    There 3 rows:
    - the first row is the navigation bar
    - the second row is the main content
    - the third row is the footer

    start script with setup attribute
 -->
<script setup>
// import RoboNationCore from composables
import RoboNationCore from '~/composables/RoboNationCore';
// import {useModalStore} from store
import { useModalStore } from '~/store/modal';
import useGameStore from '~/store/game';

// use const t for i18n
const { t } = useI18n();

// get router
const router = useRouter();
// add a const for useRoute
const route = useRoute();

// add a ref for isGamePaused
const isGamePaused = ref(false);

const isNewPage = ref(false);

// add a const for locale from i18n
const locale = useI18n().locale;

/*
    add a ref for a submenu items. It is an array of object.
    this submenu will allow to manage the game.
    Each object has :
        - a name (use i18n key, with prefix global.)
        - a path
        - a boolean to know if the link is active
        - a theme
        - a icon
    For now it will be 'Settings', 'Save game', 'Quit game'
        [
            {
                name: 'global.settings',
                path: '/settings/',
                active: false,
                theme: 'primary',
                icon: 'assembly',
            },
            {
                name: 'global.saveGame',
                path: '/save-game/',
                active: false,
                theme: 'sales',
                icon: 'protected',
            },
            {
                name: 'global.quitGame',
                path: '/quit-game/',
                active: false,
                theme: 'secondary',
                icon: 'stop',
            },
        ]
*/
const adminItems = ref([
    {
        name: 'global.settings',
        btnCallback: () => enterSettings(),
        active: false,
        theme: 'assembly',
        icon: 'small-gear',
    },
    {
        name: 'global.continue',
        btnCallback: () => continueGame(),
        active: false,
        theme: 'primary',
        icon: 'small-disk',
    },
    {
        name: 'global.saveGame',
        btnCallback: () => saveGame(),
        active: false,
        theme: 'sales',
        icon: 'small-protected',
    },
    {
        name: 'global.quitGame',
        btnCallback: () => quitGame(),
        active: false,
        theme: 'secondary',
        icon: 'small-stop',
    },
]);

// add a ref for showPanel, set it to false
const showPanel = ref(false);
const showEventNotification = ref(false);
// add ref for leave transition duration. Set it to 2000
const leaveTransitionDuration = ref(300);
// add a computed to add 'ms' to leaveTransitionDuration
const cssLeaveTransitionDuration = computed(() => {
    return leaveTransitionDuration.value + 'ms';
});

// add a method for each submenu item
// - enterSettings
// - saveGame
// - quitGame
// each method will open a modal
// for now, just add a console.log with the name of the method
const enterSettings = () => {
    // open modal with useModalStore with Title Settings and content 'Settings'
    useModalStore().openModal({
        title: 'global.settings',
        button1: {
            open: true,
            text: 'global.close',
            callback: null,
            theme: 'secondary',
            extClass: '',
        },
        slider: {
            open: true,
        },
    });
};

/*
    saveGame:
    different cases can happen:
    - we save the game for the first time
    - the game is already saved, we overwrite the save
*/

const saveGame = () => {
    // redirect to save game page
    router.push('/' + locale.value + '/save-game/');
};

const isSales = ref(false);

/*
    quitGame:
    open a modal with a confirmation message (use useModalStore).
    title is 'Quit game'.
    message is 'Are you sure you want to quit the game?'
    Every text is in the language of the game (use i18n, with prefix global.)
    use a second button of the modal to redirect to the home page
*/

const quitGame = () => {
    // open modal with useModalStore with Title Quit game and message 'Are you sure you want to quit the game?'
    // use i18n for every text and  label (use prefix global.)
    // you don't need to define button 1, it will be the default button (cancel button)
    useModalStore().openModal({
        title: t('global.quitGame'),
        message: t('global.modal.quitGameMessage'),
        button2: {
            open: true,
            text: t('global.quit'),
        },
    });
    // susbcribe to button2 click event
    // if button2 is clicked, redirect to home page
    // use the same susbcribe method as in save-game.vue
    useModalStore().$subscribe((mutation, state) => {
        if (state.button2.isClicked) {
            useModalStore().closeModal();
            router.push('/' + locale.value);
        }
    });
};

// add a method for continueGame, it redirect to load-game page
const continueGame = () => {
    router.push('/' + locale.value + '/load-game/');
};

const handleEmitIsSales = (isSalesArg = false) => {
    isSales.value = isSalesArg;
};

// watch route, if route include 'game-pause', set isGamePaused to true, else set it to false
const watchRoutePath = watch(
    () => route.path,
    () => {
        if (route.path.includes('game-pause')) {
            isGamePaused.value = true;
            // pause game with robonation core instance pause() function
            RoboNationCore.instance.pause();
        } else {
            isGamePaused.value = false;
            // resume game with robonation core instance resume() function
            RoboNationCore.instance.resume();
        }
    },
    { immediate: true }
);

onBeforeRouteLeave((to, from, next) => {
    isNewPage.value = false;
    // wait for leave transition to finish
    setTimeout(() => {
        next();
    }, leaveTransitionDuration.value);
});

// on mounted set isNewPage to true
onMounted(() => {
    isNewPage.value = true;
});

// on before unmount, remove watchRoutePath
onBeforeUnmount(() => {
    watchRoutePath();
});
</script>

<!--
    template:
    add a div with class c-layout. Use tailwind class to set the dimension to full screen width and Height.

    add a div with class c-layout__wrapper. it will contain the 3 rows.
    add a div with class c-layout__navigation. it will contain the navigation bar.
    add a div with class c-layout__content. it will contain the main content.
    add a div with class c-layout__footer. it will contain the footer.


    more style will be added later in the style tag
    -->
<template>
    <div class="c-layout__wrapper">
        <div class="c-layout__navigation">
            <div class="relative">
                <template v-if="useGameStore().isEvent.inProgress">
                    <Button
                        :icon="'horn'"
                        :variant="'c-btn--square c-btn--ghost'"
                        :theme="'primary'"
                        @click="showEventNotification = !showEventNotification"
                    >
                    </Button>
                    <Transition name="fade">
                        <div v-show="showEventNotification" class="absolute left-0 top-20 z-50">
                            <Notification
                                :event="{
                                    content: RoboNationCore.instance.currentEvent,
                                    isUpdate: useGameStore().isEvent.isUpdate,
                                }"
                                :status="true"
                                :theme="'primary'"
                                style="--notification-width: 458px"
                                @click="showEventNotification = false"
                            />
                        </div>
                    </Transition>
                </template>
            </div>
            <Navigation />
        </div>
        <div class="c-layout__content">
            <Transition name="page-frame">
                <div v-if="isNewPage">
                    <div class="c-page-frame relative max-h-[600px]">
                        <div class="c-page-frame__background h-full w-full"></div>
                        <Transition name="fade">
                            <div v-if="isNewPage">
                                <div
                                    class="c-page-frame__layout u-gradient-border theme-primary flex flex-col gap-y-4 p-8"
                                >
                                    <div v-if="!isGamePaused" class="c-page-frame__header flex w-full justify-between">
                                        <!-- in next div, content is in one column with a vertical gap of 8px (reminder , index for tailwind is multiple of 4), justify is start -->
                                        <div class="flex max-w-[676px] flex-col justify-start gap-2">
                                            <slot name="header" />
                                        </div>
                                        <GameProgression />
                                    </div>
                                    <slot v-if="!isGamePaused" name="subnav" />
                                    <!-- set variable --page-frame-content-height to 22rem -->
                                    <div
                                        class="c-page-frame__content w-full"
                                        :style="{ '--page-frame-content-height': '22rem' }"
                                    >
                                        <!--
                                                    add a separation line with
                                                    - 2px height
                                                    - full width
                                                    - color is primary gradient (use tailwing class bg-primary-500)
                                                    - add a vertical spacing about 16px above and behind the line (use a tailwind class my-4)
                                                -->

                                        <div v-if="!isGamePaused" class="mb-4 mt-2 h-[1px] w-full bg-primary-500"></div>

                                        <NuxtPage @emit-is-sales="(value) => handleEmitIsSales(value)" />
                                    </div>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
            </Transition>
        </div>
        <div class="c-layout__footer relative z-50">
            <!--
                add a button. The style of the button is the same as Navigation button.
                it keep the same color as the next button on this footer.
                use icon is 'assembly'.
                On click, a submenu will be displayed. Just add a empty div for the moment for now.
                add status active if showPanel is true
            -->
            <Button
                :icon="'assembly'"
                :variant="'c-btn--square c-btn--ghost'"
                :theme="'primary'"
                :status="showPanel ? 'active' : ''"
                @click="showPanel = !showPanel"
            />
            <div class="c-btn-subpanel">
                <TransitionGroup tag="div" name="fade" class="c-btn-subpanel__content">
                    <template v-if="showPanel">
                        <Button
                            v-for="(item, index) in adminItems"
                            :key="index"
                            :label="$t(item.name)"
                            :theme="item.theme"
                            :icon="item.icon"
                            :style="{ '--fade-delay': index }"
                            @click="item.btnCallback"
                        />
                    </template>
                </TransitionGroup>
            </div>
            <!--
                add a link if isSales is true, it will redirect to sales/map page
                - theme is 'sales'
                - label is i18n key 'sales.nationsMap'
                - icon is 'smallPlanet'
             -->
            <Transition name="fade">
                <NuxtLink v-if="isSales" :to="'/' + $i18n.locale + '/sales/map'">
                    <Button :label="$t('sales.nationsMap')" theme="sales" icon="smallPlanet" />
                </NuxtLink>
            </Transition>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.c-layout__wrapper {
    // assign --page-frame-transition-duration to leaveTransitionDuration with v-bind
    --page-frame-transition-duration: v-bind(cssLeaveTransitionDuration);
}

.c-layout__navigation {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}
// add page-frame transition
// the transition will transform the shape of a div. The div display the main content.
// on enter and on leave end the shape a square about 100px x 100px !
// on enter, the square will grow horizontally to the reach 100% of its width
// when the width is full it will grow vertically to reach 100% of its height!
// on leave, the square will shrink vertically to reach 100px
// when the height is 100px, it will shrink horizontally to reach 100px
// duration is 0.3s
// timing function is linear
// for performance reason, use transform instead of width and height
// because of the different step (keyframe) during the transition, you should use animation instead of transition for this effect

.page-frame-enter-active {
    animation: page-frame-in var(--page-frame-transition-duration) linear;
}

.page-frame-leave-active {
    animation: page-frame-in var(--page-frame-transition-duration) linear reverse;
}

// during page frame animation, the opacity of c-page-frame__layout must be go from 0 to 1
// duration is 0.3s
// timing function is linear
// use transition
.c-page-frame__layout {
    transition: opacity calc(var(--page-frame-transition-duration) / 2) linear;
}

.page-frame-enter-from,
.page-frame-leave-to {
    .c-page-frame__layout > div {
        opacity: 0;
    }
}

@keyframes page-frame-in {
    0% {
        transform: scaleX(0.1) scaleY(0.2);
    }
    50% {
        transform: scaleX(1) scaleY(0.2);
    }
    100% {
        transform: scaleX(1) scaleY(1);
    }
}

.c-btn-subpanel {
    @apply absolute left-0;
    bottom: calc(var(--btn-square-height) + 10px);
}

.c-btn-subpanel__content {
    @apply flex flex-col gap-2.5;
}
</style>
